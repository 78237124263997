import { defineStore } from 'pinia'

const range = (start, end) => {
  return [...Array(end - start + 1).keys()].map(i => i + start)
}

export const useGuestsStore = defineStore('guests', {
  state: () => {
    return {
      personen: 2,
      adults: 2,
      children: 0,
      children_ages: [],
      pets: 0,
      errorAdultsMaximumExceeded: false as false | number
    }
  },
  getters: {
    getChildrenAges: state => range(0, state.children - 1).map(value => state.children_ages[value] ?? '')
  },
  actions: {
    setPersonen (value) {
      this.$patch({
        personen: parseInt(value),
        adults: parseInt(value),
        children: 0
      })
    },
    setAdults (value) {
      this.$patch({
        adults: parseInt(value),
        personen: parseInt(value) + this.children
      })
    },
    setChildren (value) {
      this.$patch({
        children: parseInt(value),
        personen: parseInt(value) + this.adults
      })
    },
    setPets (value) {
      this.$patch({
        pets: parseInt(value)
      })
    },
    setPersonenKeepingChildren (value) {
      this.$patch({
        personen: parseInt(value),
        adults: parseInt(value) - this.children,
        children: (this.children < parseInt(value)) ? this.children : 0
      })
    },
    setChildrenKeepingPersonen (value) {
      this.$patch({
        children: parseInt(value),
        adults: this.personen - parseInt(value)
      })
    },
    readFromParamsWithCheckingMaximum (params, productGuests) {
      this.setPersonen(params.personen)
      if (params.personen <= productGuests.max_adults) {
        return
      }
      this.setPersonen(productGuests.max_adults)
      this.errorAdultsMaximumExceeded = productGuests.max_adults
      watch(this, () => {
        this.errorAdultsMaximumExceeded = false
      }, { once: true })
    }
  }
})
