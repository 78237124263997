<template>
  <button v-if="wishlistRemove" :title="t('delete')" class="bg-neutral-900/30 rounded-lg py-2 px-2" @click="removeFromWishList">
    <UiIcon src="/svg/icons/trashcan.svg" width="22" height="22" class="fill-white inline-block" />
  </button>
  <button v-if="wishlistProductPage" class="tooltip" @click="changeStatus">
    <UiProductButton>
      <template #icon>
        <UiIcon
          v-show="status"
          src="/svg/icons/heart-fill.svg"
          width="22"
          height="22"
          class="fill-cta-500"
          :class="{'heart_animate': animation}"
        />
        <UiIcon
          v-show="!status"
          src="/svg/icons/heart-stroke.svg"
          width="22"
          height="22"
          class="fill-white"
          :class="{'heart_animate': animation}"
        />
      </template>
      <template #text>
        <span class="hidden lg:block ml-1">
          {{ t('save') }}
        </span>
      </template>
    </UiProductButton>
  </button>
  <button v-if="!wishlistRemove && !wishlistProductPage" :aria-label="status ? t('deactive_wishlist') : t('active_wishlist')" class="bg-neutral-900/30 rounded-lg py-2 px-2 tooltipcard" @click="changeStatus">
    <UiIcon
      v-show="status"
      src="/svg/icons/heart-fill.svg"
      width="22"
      height="22"
      class="fill-cta-500 inline-block"
      :class="{'heart_animate': animation}"
    />
    <UiIcon
      v-show="!status"
      src="/svg/icons/heart-stroke.svg"
      width="22"
      height="22"
      class="fill-white inline-block"
      :class="{'heart_animate': animation}"
    />
    <WishlistTooltip :status="status" />
  </button>
</template>

<script setup lang="ts">
import type { Ref } from 'vue'
import { useWishListStore } from '~~/stores/wishList'

const t = useTranslations('WishlistHeartIcon').translate

const props = defineProps({
  objectId: {
    type: [Number, String],
    default: null
  },
  wishlistStyle: {
    type: String,
    default: ''
  }
})

const wishlistRemove = computed(() => props.wishlistStyle === 'remove')
const wishlistProductPage = computed(() => props.wishlistStyle === 'product_page')

const animation: Ref<boolean> = ref(false)

const status = computed<boolean>({
  get () {
    if (import.meta.client) {
      const wishListStore = useWishListStore()
      return wishListStore.statusOfObjectInWishList(Number(props.objectId))
    }
    return false
  }
  // set (val) { wishListStore.itemsCount = val }
})

function changeStatus () {
  const wishListStore = useWishListStore()
  wishListStore.AddOrRemoveObjectInWishListAndGetStatus(Number(props.objectId))
  animation.value = true
}
function removeFromWishList () {
  const wishListStore = useWishListStore()
  wishListStore.removeObjectFromWishList(Number(props.objectId))
}
</script>

<style lang="scss" scoped>
.icon-favherz-delete-only {
  border-radius: 10rem;
  width: 32px;
  height: 32px;
  background: rgba(255,255,255,.8) none;
  padding: 4px 8px;
}
.heart_animate{
  animation: heartbeat 1s 1;
}
@keyframes heartbeat
{
  0%
  {
    transform: scale( 1 );
  }
  20%
  {
    transform: scale( .75 );
  }
  40%
  {
    transform: scale( 1 );
  }
  60%
  {
    transform: scale( .75 );
  }
  80%
  {
    transform: scale( 1 );
  }
  100%
  {
    transform: scale( 1 );
  }
}
</style>
